'use client';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import isEqual from 'deep-equal';
import momentTzOrig, { Moment } from 'moment-timezone';

import { TimezoneResponse } from '@bloom/codegen/models/TimezoneResponse';

import { momentTz } from '@bloom/library/utils/date';

import { useAccount } from './useAccounts';

export type TMoment = (...args: Parameters<typeof momentTzOrig>) => Moment;
export type TMomentTz = (date?: string, timezone?: Pick<TimezoneResponse, 'name'>) => Moment;

const emptyTimezone: TimezoneResponse = {
  abbreviation: '',
  id: '',
  name:
    typeof window !== 'undefined' && window.Intl
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : '',
  offset: '',
};

export interface IUseMomentReturn {
  moment: TMoment;
  momentTz: TMomentTz;
  timezone: TimezoneResponse;
}

export const useMoment = (): IUseMomentReturn => {
  const { account } = useAccount();

  const timezone: TimezoneResponse = useMemo(() => {
    const tz = account?.settings.timezone;

    // Make sure timezone is always an object.
    if (!tz || typeof tz === 'string') {
      // Return same instance of empty object
      return emptyTimezone;
    }

    return tz;
  }, [account?.settings.timezone]);

  const timezoneRef = useRef(timezone);
  // Note: React re-renders components when properties change, even if a component receives a new instance of the same object.
  // Every auth.user object property changes by calling UPDATE_USER action. that means even if we updated, let's say only 'defaultTaxPercentage'
  // the whole user object updates in reducer (sets a new instance from database).
  // This causes a re-render in all components that use useMoment hook due to a new instance of timezone.
  // So update timezoneRef only when there is actually a new user timezone and use timezoneRef rather than timezone object from a user instance.
  // In most cases re-render is not a problem at all, but it is critical when Formik initial value depends on moment.
  // A new instance of timezone leads to a new instance of moment function, moment function to a new instance of initialValues, new initialValues RESETs form state.
  useEffect(() => {
    if (isEqual(timezone, timezoneRef.current)) {
      timezoneRef.current = timezone;
    }
  }, [timezone]);

  const moment = useCallback((...args: Parameters<typeof momentTzOrig>) => {
    if (timezoneRef.current.name) {
      // Note: The first argument might be undefined and args.length > 0 at the same time.
      return args[0]
        ? momentTzOrig.tz(...args, timezoneRef.current.name)
        : momentTzOrig.tz(timezoneRef.current.name);
    }
    // If timezone name is not set, it falls back to local time.
    return momentTzOrig(...args);
  }, []);

  return useMemo(() => ({ moment, momentTz, timezone: timezoneRef.current }), [moment]);
};
