import moment from 'moment-timezone';

import { TimezoneResponse } from '@bloom/codegen/models/TimezoneResponse';

export function getTimes() {
  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(`${i.toString().padStart(2, '0')}:00`);
    times.push(`${i.toString().padStart(2, '0')}:30`);
  }

  return times;
}

export function getFormattedTimes(format = 'hh:mm A') {
  return getTimes().map((time) => moment(time, 'HH:mm').format(format));
}

export function formatMinutes(minutes) {
  const hours = minutes / 60;
  const days = hours / 24;
  if (days >= 1) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }
  if (hours >= 1) {
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  }
  return `${minutes} minute${minutes > 1 ? 's' : ''}`;
}

/*
 * @param Number offset is number of minutes before an event occurs.
 */
export function formatAlertOffset(offset) {
  if (offset === -1 || offset === undefined) {
    return 'None';
  }
  return parseInt(offset, 10) ? `${formatMinutes(offset)} before` : 'At time of event';
}

/*
 * Converts string date to object which
 * can be conveniently passed to moment.set() method.
 *
 * @param String date in format YYYY-MM-DD
 * @return Object date in format { year: 0000, month: 00, date: 00 }
 */
export function convertDateToObject(date: string) {
  const names = ['year', 'month', 'date'];
  const result = date.split('-').reduce((obj, val, i) => {
    obj[names[i]] = parseInt(val, 10);
    if (names[i] === 'month') {
      // Subtract one, because months start with zero.
      obj[names[i]] -= 1;
    }
    return obj;
  }, {});

  return result;
}

/*
 * Converts time to object which
 * can be conveniently passed to moment.set() method.
 *
 * @param String time in format hh:mm[:ss] - seconds are optional
 * @return Object date in format { hours: 00, minutes: 00, seconds: 00 }
 */
export function convertTimeToObject(time: string): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  if (!/^\d{2}:\d{2}(:\d{2})?$/.test(time)) {
    console.error(
      `Error calling convertTimeToObject: invalid agrument format, expected hh:mm[:ss], got '${time}'`
    );
  }

  const names = ['hours', 'minutes', 'seconds'] as const;
  const parts = time.split(':');
  const timeObject = { hours: 0, minutes: 0, seconds: 0 };
  names.forEach((name, index) => {
    const value = parts[index] || '0';
    timeObject[name] = parseInt(value, 10);
  });

  return timeObject;
}

export function momentTz(date: string, timezone?: TimezoneResponse) {
  const { name } = timezone || {};
  // If timezone name is not set, it falls back to local time.
  return name ? moment.tz(date, name) : moment(date);
}

export function normalizeDatepickerDate(date: string) {
  let normalizedDate = '';
  if (date && moment(new Date(date)).isValid()) {
    const dataObject = convertDateToObject(date.slice(0, 10));

    if (Object.values(dataObject).every((value) => !isNaN(Number(value)))) {
      normalizedDate = moment()
        .set({
          ...convertDateToObject(date.slice(0, 10)),
          hours: 0,
          minutes: 0,
          seconds: 0,
        })
        .utc()
        .format();
    } else {
      normalizedDate = moment()
        .set({
          ...convertDateToObject(moment(new Date(date)).utc().format()),
          hours: 0,
          minutes: 0,
          seconds: 0,
        })
        .utc()
        .format();
    }
  }
  return normalizedDate;
}

export function convertStringToDate(dateStr: string) {
  const date = new Date(dateStr);
  const offset = date.getTimezoneOffset();
  return new Date(date.getTime() - offset * 60 * 1000);
}

export function getMonthOffsets(startDate: Date, endDate: Date) {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();

  return (endYear - startYear) * 12 + (endMonth - startMonth);
}
